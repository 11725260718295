import React, { useGlobal, useEffect } from "reactn";
import {
  FaCode,
  FaPhone,
  FaEdit,
  FaUser,
  FaPaw,
  FaUserMd,
} from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import {
  useUsersQuery,
  useCheckPhoneVerification,
  USER_IMPERSONATION,
} from "./userGraphQL";
import PrismaServerFilterTable from "../../app/prismaServerFilterTable";
import Loading from "../../app/loading";
import { useMutation } from "@apollo/react-hooks";
import config from "../../../api/config";

const FLOW_ROLES = ["ADMIN", "MEMBER"];

export default function Users({ clinicId, userType }) {
  const [, setJson] = useGlobal("json");
  const [checkPhone, checkPhoneData] = useCheckPhoneVerification();
  let [modalState, setGlobalModalState] = useGlobal("modalState");
  const [impersonate] = useMutation(USER_IMPERSONATION);
  const history = useHistory();

  // Allows for query URLs
  const location = useLocation();
  const userUrlQueries = new URLSearchParams(location.search);

  function checkUserPhone(user) {
    return () => {
      setGlobalModalState({
        ...modalState,
        showModal: true,
        modalHeader: "Checking Phone Verification",
        modalContent: <Loading />,
      });
      checkPhone({
        variables: {
          data: { id: user.firebaseId },
        },
      });
    };
  }
  useEffect(() => {
    if (checkPhoneData.data) {
      setGlobalModalState({
        ...modalState,
        showModal: true,
        modalHeader: "Phone Verification Complete",
        modalContent:
          checkPhoneData.data.checkPhoneVerification &&
          checkPhoneData.data.checkPhoneVerification.exists === true
            ? "User phone has been verified"
            : "User phone either does not exist or has not been verified",
      });
    }
    // eslint-disable-next-line
  }, [checkPhoneData.data]);

   //Set query variables 
  let queryVariables = { orderBy: {"lastName" : "asc"}, where: {}, take: 500};

  if (clinicId && userType === "VET") {
    queryVariables.where = {
      ...queryVariables.where,
      clinics: {
        some: {
          id: {
            equals: clinicId
          }
        }
      },
      type: {equals: userType},
    };
    queryVariables.staffRoleWhere = {
      clinic: {
        id: {equals: clinicId},
      },
    };
  }

  function showImpersonateConfirm({
    firebaseId,
    firstName,
    lastName,
    type,
    id,
    vetInfo,
  }) {
    modalState = {
      ...modalState,
      modalHeader: "Confirm Impersonation",
      modalContent: `Are you sure you want to impersonate ${firstName} ${lastName}`,
      showModal: true,
      modalConfirm: async () => {
        const {
          data: {
            createImpersonation: { token },
          },
        } = await impersonate({
          variables: { data: { id: firebaseId } },
        });
        switch (type) {
          case "OWNER":
            window.open(`${config.petWeb}?token=${token}`, "_blank");
            break;
          case "BETA":
            window.open(`${config.beta}/impersonate/${token}${clinicId ? `?clinicId=${clinicId}` : ''}`, "_blank");
            break;
            default:
            window.open(`${config.vetWebApp}?token=${token}/#/login`, "_blank");
            break;
        }
      },
    };

    setGlobalModalState(modalState);
  }

  const hasFlowRole = (roles) => {
    if (!roles) return false;
    for (const staffRole of roles) {
      if (FLOW_ROLES.includes(staffRole.role)) return true;
    }
    return false;
  };

  return (
    <PrismaServerFilterTable
      prismaVersion = {2}
      title="Users"
      queryName="findManyUser"
      useQuery={useUsersQuery}
      queryVariables={queryVariables}
      filterConfigs={[
        { property: "firstName", display: "FirstName" },
        { property: "lastName", display: "LastName" },
        { property: "email", display: "Email" },
        { property: "phone", display: "Phone" },
        {
          property: "type",
          display: "Type",
          type: "select",
          options: [
            { value: "VET", display: "Vet" },
            { value: "OWNER", display: "Owner" },
          ],
        },
      ]}
      columns={[
        {
          Header: "",
          hidefilter: true,
          blockSort: true,
          accessor: ({ data }) => {
            return (
              <div>
                <FaEdit
                  color="#2ABA66"
                  size={20}
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  title="View Json"
                  onClick={() => {
                    history.push(`/users/${data.id}`);
                  }}
                />
                <FaCode
                  color="#2ABA66"
                  size={20}
                  style={{ marginLeft: 8, cursor: "pointer" }}
                  title="View Json"
                  onClick={() => {
                    setJson({ type: "users", data });
                    history.push("/json/view");
                  }}
                />
                {data.type === "VET" ? (
                  <span onClick={() => showImpersonateConfirm(data)}>
                    <FaUser
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Impersonate"
                    />
                  </span>
                ) : null}

                {data.type === "VET" && hasFlowRole(data?.vetInfo?.roles) ? (
                  <span
                    onClick={() =>
                      showImpersonateConfirm({ ...data, type: "BETA" })
                    }
                  >
                    <FaUserMd
                      size={20}
                      style={{ marginLeft: 8, cursor: "pointer" }}
                      title="Impersonate"
                    />
                  </span>
                ) : null}

                {data.type === "OWNER" ? (
                  <FaPaw
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    title="Impersonate"
                    onClick={() => showImpersonateConfirm(data)}
                  />
                ) : null}

                {
                  <FaPhone
                    color="#2ABA66"
                    size={20}
                    style={{ marginLeft: 8, cursor: "pointer" }}
                    title="Check Phone Verification"
                    onClick={checkUserPhone(data)}
                  />
                }
              </div>
            );
          },
        },
        { Header: "Firstname", accessor: "firstName" },
        { Header: "Lastname", accessor: "lastName" },
        { Header: "Email", accessor: "email" },
        {
          Header: "Phone",
          accessor: "phone",
          Cell: ({ value }) => value,
        },
        { Header: "Type", accessor: "type" },
      ]}
      urlQueries = {userUrlQueries}
    />
  );
}
